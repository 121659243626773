import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ownerDocument from 'dom-helpers/ownerDocument';

function getContainer(container, defaultContainer) {
  const thisContainer = typeof container === 'function' ? container() : container;
  // eslint-disable-next-line react/no-find-dom-node
  return ReactDOM.findDOMNode(thisContainer) || defaultContainer;
}

function getOwnerDocument(element) {
  let domNode = document.body;
  // eslint-disable-next-line react/no-find-dom-node
  try { domNode = ReactDOM.findDOMNode(element); } catch (err) { /* no catch needed... */ }
  return ownerDocument(domNode);
}

class Portal extends React.Component {
  componentDidMount() {
    this.setContainer(this.props.container);
    this.forceUpdate(this.props.onRendered);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.container !== this.props.container) {
      this.setContainer(this.props.container);
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    this.mountNode = null;
  }

  setContainer(container) {
    this.mountNode = getContainer(container, getOwnerDocument(this).body);
  }

  getMountNode = () => {
    return this.mountNode;
  };

  render() {
    const { children } = this.props;

    return this.mountNode ? ReactDOM.createPortal(children, this.mountNode) : null;
  }
}

Portal.propTypes = {
  /**
   * The children to render into the `container`.
   */
  children: PropTypes.node.isRequired,
  /**
   * A node, component instance, or function that returns either.
   * The `container` will have the portal children appended to it.
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Callback fired once the children has been mounted into the `container`.
   */
  onRendered: PropTypes.func,
};

export default Portal;
