// Vendor imports
import React from 'react';
import PropTypes from 'prop-types';

// Local imports
import MenuIcon from './icons/Menu';
import HelpIcon from './icons/Help';
import ExpandMoreIcon from './icons/ExpandMore';
import Button from './Button';
import Menu from './Menu';
import LoginModal from './LoginModal';
import PasswordResetModal from './PasswordResetModal';
import PasswordChangeModal from './PasswordChangeModal';
import Auth from './Auth';

import '../styles.css';

/**
 * The root toolbar component, containing the various action items on the toolbar, as well as
 * orchestration of the handlers/state for the various child modals (login, password reset, and
 * password change)
 */
class HydraToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAppMenu: false,
      appMenuAnchor: null,
      showUserMenu: false,
      userMenuAnchor: null,
      showLoginModal: false,
      showPasswordResetModal: false,
      showPasswordChangeModal: false,
      menuItems: [],
    };

    this.origin = this.props.origin ? this.props.origin : window.location.origin;
    this.disableHelpGuide = !this.props.disableHelpGuide;
    this.helpGuidePath = this.props.helpGuidePath
      ? this.props.helpGuidePath
      : `/help/${document.location.pathname.split('/')[1]}`;
    this.onChangePasswordSelect = this.onChangePasswordSelect.bind(this);
    this.onLogOutSelect = this.onLogOutSelect.bind(this);
    this.onSignInClick = this.onSignInClick.bind(this);
    this.onResetPasswordClick = this.onResetPasswordClick.bind(this);
    this.onSendPasswordResetClick = this.onSendPasswordResetClick.bind(this);
    this.onBackToSignInClick = this.onBackToSignInClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.appMenuToggle = this.appMenuToggle.bind(this);
    this.onAppMenuClose = this.onAppMenuClose.bind(this);
    this.onUserMenuClick = this.onUserMenuClick.bind(this);
    this.onUserMenuClose = this.onUserMenuClose.bind(this);
    this.onChangePasswordCancel = this.onChangePasswordCancel.bind(this);
    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
    this.onNotAuthorizedResponse = this.onNotAuthorizedResponse.bind(this);
    this.redirectIfNotAuthorized = this.redirectIfNotAuthorized.bind(this);
    this.parsePath = this.parsePath.bind(this);
    this.onLoginSelect = this.onLoginSelect.bind(this);
    this.onLoginAttemptClose = this.onLoginAttemptClose.bind(this);

    document.addEventListener('iron-signal', this.onNotAuthorizedResponse);
  }

  componentDidMount() {
    // Get any user details from localStorage and attempt to set it to state
    this.setState({ user: Auth.getUser() }, () => {
      // If there was no user, show the login modal
      if (!this.state.user && !this.props.loginNotRequired) {
        this.setState({ showLoginModal: true });
      } else if (this.state.user) { // Otherwise, generate the menuItems if we have a user
        this.setState(prevState => {
          return { menuItems: this.createMenuItems(prevState.user.apps) };
        });
        this.redirectIfNotAuthorized('/');
      }
    });
  }

  // Select the "Change Password" menu item in the right group's account dropdown menu
  onChangePasswordSelect() {
    this.setState({ showPasswordChangeModal: true });
  }

  // Select the "Logout" menu item in the right group's account dropdown menu
  onLogOutSelect() {
    // `Auth#logout` returns undefined + show the login modal
    this.setState({
      user: Auth.logOut(),
      showLoginModal: true,
      menuItems: [],
    });
    this.props.onLogOut();
  }

  onLoginSelect() {
    this.setState({
      showLoginModal: true,
    });
  }

  onLoginAttemptClose() {
    if (this.props.loginNotRequired) {
      this.setState({
        showLoginModal: false,
      });
    }
  }

  // Click the "Sign In" button inside the login modal
  onSignInClick({ username, password }) {
    this.setState({ signInLoading: true, signInError: null });

    Auth.logIn({ origin: this.origin, username, password }).then((user) => {
      this.setState({
        user,
        showLoginModal: false,
        menuItems: this.createMenuItems(user.apps),
      });
      this.props.onSignIn();
    }, (err) => {
      if (err.status === 401) {
        this.setState({ signInError: 'Authentication failed' });
      } else {
        this.setState({ signInError: 'Server error' });
      }
    }).finally(() => this.setState({ signInLoading: false }));
  }

  // Click the "Reset Password" button inside the login modal
  onResetPasswordClick() {
    this.setState({
      signInError: null,
      showLoginModal: false,
      showPasswordResetModal: true,
    });
  }

  // Click the "Send Reset Password Link" button inside the password reset modal
  onSendPasswordResetClick({ username }) {
    this.setState({ sendPasswordResetLoading: true, sendPasswordResetError: null });

    Auth.resetPassword({ origin: this.origin, username }).then(() => {
      this.setState({
        showLoginModal: true,
        showPasswordResetModal: false,
      });
    }, () => {
      this.setState({ sendPasswordResetError: 'Server error' });
    }).finally(() => this.setState({ sendPasswordResetLoading: false }));
  }

  // Click the "Back To Sign In" button inside the password reset modal
  onBackToSignInClick() {
    this.setState({
      sendPasswordResetError: null,
      showLoginModal: true,
      showPasswordResetModal: false,
    });
  }

  onMenuItemClick(href) {
    window.location.href = href;
  }

  onAppMenuClose() {
    this.setState({ showAppMenu: false });
  }

  onUserMenuClick(event) {
    this.setState({ showUserMenu: true, userMenuAnchor: event.currentTarget });
  }

  onUserMenuClose() {
    this.setState({ showUserMenu: false });
  }

  onChangePasswordCancel() {
    this.setState({
      showPasswordChangeModal: false,
    });
  }

  // Click the "Change Password" button inside the password change modal
  onChangePasswordClick(passwordArgs) {
    this.setState({ passwordChangeLoading: true, passwordChangeError: null });

    Auth.changePassword({
      origin: this.origin,
      username: this.state.user.email,
      ...passwordArgs,
    }).then(() => {
      this.setState({
        showPasswordChangeModal: false,
        passwordChangeError: null,
        passwordChangeLoading: false,
      });
    }, (error) => {
      if (error.status === 401) {
        this.setState({
          passwordChangeError: 'Authentication failed',
          passwordChangeLoading: false,
        });
      } else {
        this.setState({
          passwordChangeError: error.message,
          passwordChangeLoading: false,
        });
      }
    });
  }

  onNotAuthorizedResponse(e) {
    if (e.detail && e.detail.name && e.detail.name === 'not-authorized') {
      this.onLogOutSelect();
    }
  }

  getAppHref(displayName) {
    return `/${displayName.toLowerCase().replace(' ', '-')}/`;
  }

  appMenuToggle(event) {
    if (!this.state.showAppMenu) this.setState({ showAppMenu: true, appMenuAnchor: event.currentTarget });
    else this.setState({ showAppMenu: false });
  }

  parsePath() {
    const pathTokens = document.location.pathname.split('/');
    const basePath = pathTokens.slice(1, 2);
    return (basePath[0] === '') ? '/' : `/${basePath}/`;
  }

  redirectIfNotAuthorized(destination = '/') {
    if (this.props.isNativeApp) return;

    const currentLocation = this.parsePath();
    const allowedUris = this.state.user.apps.map((app) => {
      return (app.href) ? app.href : this.getAppHref(app.display_name);
    });
    if (allowedUris.includes('/calendar/')) allowedUris.push('/calendar-ui/');
    if (allowedUris.includes('/athena/')) allowedUris.push('/athena-beta/');
    allowedUris.push('/');
    const isAllowed = (allowedUris.indexOf(currentLocation) !== -1);
    if (!isAllowed) window.location = destination;
  }

  // Creates the left menu items; this is done outside of the `render` method as they only need to
  // be mapped over once and `render` is likely called multiple times
  createMenuItems(apps = []) {
    if (apps.length > 0 && !this.props.isNativeApp) {
      // Sort apps by their display name
      apps.sort((a, b) => (a.display_name <= b.display_name ? -1 : 1));

      // Filter out the app we're currently on
      let filteredApps = apps.filter((app) => {
        const thisAppName = window.location.pathname.split('/').splice(1, 2)[0];
        return thisAppName !== app.display_name.toLowerCase().replace(' ', '-');
      });

      if (document.location.pathname !== '/') {
        // Don't add the Dashboard link when in the Dashboard
        filteredApps = [{
          display_name: 'Dashboard',
          href: '/',
          className: 'app-menu-item-dashboard',
        }].concat(filteredApps);
      }
      // Loop over the given plain-object `menuItems` collection and create a menu item for each
      return filteredApps.map((app, i) => {
        const item = {
          primaryText: app.display_name,
          href: (app.href) ? app.href : this.getAppHref(app.display_name),
          className: app.className,
        };

        // Do the same for nested menuItems up to a single depth
        if (app.menuItems) {
          item.menuItems = this.createMenuItems(app.menuItems);
        }
        return this.createMenuItem(item, i);
      });
    }

    return apps;
  }

  // Creates a single menu item given a plain object and a key
  createMenuItem(item, key) {
    const { primaryText, href, ...others } = item;
    return (
      <li
        key={`item-${key}`}
        onClick={() => { this.onMenuItemClick(href); }}
        {...others}
      >
        {primaryText}
      </li>
    );
  }

  render() {
    // App-defined menu on the left side; only appears when items have been defined
    const customMenu = (
      <div>
        <Button
          type="icon"
          onClick={this.appMenuToggle}
        >
          <MenuIcon />
        </Button>

        <Menu
          open={this.state.showAppMenu}
          anchorEl={this.state.appMenuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={this.onAppMenuClose}
        >
          {this.state.menuItems}
        </Menu>
      </div>
    );
    const accountDropdown = (
      <div>
        <Button
          type="icon"
          className="account-dropdown"
          onClick={this.onUserMenuClick}
        >
          <span>{this.state.user && `${this.state.user.firstName} ${this.state.user.lastName}`}</span>
          <ExpandMoreIcon />
        </Button>

        <Menu
          open={this.state.showUserMenu}
          anchorEl={this.state.userMenuAnchor}
          anchorDirection="right"
          onClose={this.onUserMenuClose}
        >
          <li onClick={this.onChangePasswordSelect}>Change Password</li>
          <li onClick={this.onLogOutSelect}>Logout</li>
        </Menu>
      </div>
    );
    const loginLink = (
      <div>
        <Button type="icon" onClick={this.onLoginSelect}>Login</Button>
      </div>
    );
    return (
      <div id={this.props.id} className={['hydra-toolbar', this.props.className].join(' ').trim()}>
        <div className="toolbar-group"> {/* Skip firstChild={true}, we like the left gap */}
          {(!this.props.isNativeApp && this.state.menuItems && this.state.menuItems.length > 0) ? customMenu : null}
          <span className="toolbar-title">{this.props.toolbarTitle}</span>
        </div>
        <div className="toolbar-group center">
          {this.props.children}
        </div>
        <div className="toolbar-group"> {/* Skip lastChild={true}, we like the right gap */}
          {this.disableHelpGuide && (
            <Button
              type="icon"
              href={this.helpGuidePath}
              target="_blank"
            >
              <HelpIcon />
            </Button>
          )}
          {(this.state.user) ? accountDropdown : loginLink}
        </div>

        <LoginModal
          show={this.state.showLoginModal}
          onSignInClick={this.onSignInClick}
          onResetPasswordClick={this.onResetPasswordClick}
          loading={this.state.signInLoading}
          errorMessage={this.state.signInError}
          onClose={this.onLoginAttemptClose}
        />
        <PasswordResetModal
          show={this.state.showPasswordResetModal}
          onSendPasswordResetClick={this.onSendPasswordResetClick}
          onBackToSignInClick={this.onBackToSignInClick}
          loading={this.state.sendPasswordResetLoading}
          errorMessage={this.state.sendPasswordResetError}
        />
        <PasswordChangeModal
          show={this.state.showPasswordChangeModal}
          onChangePasswordCancel={this.onChangePasswordCancel}
          onChangePasswordClick={this.onChangePasswordClick}
          loading={this.state.passwordChangeLoading}
          errorMessage={this.state.passwordChangeError}
        />
      </div>
    );
  }
}

HydraToolbar.propTypes = {
  /**
   * Overrides for HTML id and class
   */
  id: PropTypes.string,
  className: PropTypes.string,
  /**
   * Collection of objects used to render the left toolbar menu. All properties defined by Material
   * UI's `MenuItem` are allowed. To enable nested `MenuItem`s, rather than importing and creating
   * an array of `<MenuItem>`s, simply set the nested `menuItem` property as an array of more plain
   * objects and this library will create the `MenuItem` components for you.
   *
   * See http://www.material-ui.com/#/components/menu for available `MenuItem` properties
   *
   * If this is excluded or no items are present, the left menu button will not be rendered.
   */
  menuItems: PropTypes.arrayOf(PropTypes.object),
  /**
   * Main toolbar title; will appear on the left, to the right of the left menu if present.
   * Defaults to 'Hydra'.
   */
  toolbarTitle: PropTypes.string,
  /**
   * Whether the instantiating application is native (e.g. Electron). Defaults to false.
   */
  isNativeApp: PropTypes.bool,
  /**
   * Whether a user login is required. If set to true, then modal and redirect will not happen, even without a user present
   */
  loginNotRequired: PropTypes.bool,
  /**
   * A custom node (containing anything) that will be rendered between the two opposite toolbar
   * groups.
   */
  children: PropTypes.node,
  /**
   * An optional origin to pass to the toolbar for authentication requests. If not set, it will default
   * to the domain of the application the toolbar is included in.
   *
   * Example: https://env.domain.com
   */
  origin: PropTypes.string,
  /**
   * Optional handlers for sign-in/log-out events
   */
  onSignIn: PropTypes.func,
  onLogOut: PropTypes.func,
  /**
   * Whether or not to display the help guide icon. Defaults to true.
   */
  disableHelpGuide: PropTypes.bool,
  /**
   * Override for manual help guide path
   */
  helpGuidePath: PropTypes.string,
};

HydraToolbar.defaultProps = {
  id: 'hydra-toolbar',
  menuItems: [],
  toolbarTitle: 'Hydra',
  isNativeApp: false,
  loginNotRequired: false,
  onSignIn: () => {},
  onLogOut: () => {},
  disableHelpGuide: false,
};

export default HydraToolbar;
