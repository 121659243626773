import React from 'react';
import PropTypes from 'prop-types';

import Portal from './Portal';

import '../styles.css';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  handleOverlayClick(event) {
    if (event.target !== event.currentTarget) {
      return;
    }

    if (this.props.onClose) {
      this.props.onClose(event, 'backdropClick');
    }
  }

  render() {
    const { anchorEl, anchorDirection, invisibleOverlay, dense } = this.props;

    const modalOverlayClasses = ['modal-overlay'];
    if (invisibleOverlay) modalOverlayClasses.push('invisible');

    const modalStyles = {};
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      Object.assign(modalStyles, {
        position: 'absolute',
        top: rect.top + rect.height,
      });
      if (anchorDirection === 'left') {
        Object.assign(modalStyles, { left: rect.left });
      } else {
        Object.assign(modalStyles, { right: document.body.clientWidth - rect.right });
      }
    }
    if (dense) {
      modalStyles.minWidth = '50px';
    }

    return (
      <Portal>
        {this.props.open && (
          <div className="htr-modal">
            <div className={modalOverlayClasses.join(' ')} onClick={this.handleOverlayClick} />
            <div className="modal-inner" style={modalStyles}>
              {this.props.title && <h2 className="modal-title">{this.props.title}</h2>}
              {this.props.children}
            </div>
          </div>
        )}
      </Portal>
    );
  }
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
  anchorDirection: PropTypes.string,
  title: PropTypes.string,
  invisibleOverlay: PropTypes.bool,
};

Modal.defaultProps = {
  anchorDirection: 'left',
  invisibleOverlay: false,
};

const ModalBody = (props) => {
  const { children, ...others } = props;
  return <div className="modal-body" {...others}>{children}</div>;
};

const ModalFooter = (props) => {
  const { children, ...others } = props;
  return <div className="modal-footer" {...others}>{children}</div>;
};

export {
  Modal,
  ModalBody,
  ModalFooter,
};
