import React from 'react';

import '../../styles.css';

export default (props) => {
  return (
    <svg
      className="htr-icon"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      {props.children}
    </svg>
  );
};
