import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from './Modal';

import '../styles.css';

const Menu = (props) => {
  return (
    <Modal invisibleOverlay dense {...props}>
      <ul className="htr-menu">{props.children}</ul>
    </Modal>
  );
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default Menu;
