import React from 'react';

import '../styles.css';

export default (props) => {
  const { type, children, className, ...other } = props;
  let buttonClasses = ['htr-button'];
  if (className) buttonClasses = buttonClasses.concat(className.split(' '));
  if (type) buttonClasses.push(type);
  return (
    <button type="button" className={buttonClasses.join(' ')} {...other}>
      {children}
    </button>
  );
};
