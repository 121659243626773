// Vendor imports
import React from 'react';
import PropTypes from 'prop-types';

// Local imports
import { Modal, ModalBody, ModalFooter } from './Modal';
import Input from './Input';
import Button from './Button';

import '../styles.css';

/**
 * The password reset modal component; passes most logic up to the parent component
 */
class PasswordResetModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { username: '' };

    this.onInputChange = this.onInputChange.bind(this);
    this.checkIsSubmit = this.checkIsSubmit.bind(this);
    this.onSendPasswordResetClick = this.onSendPasswordResetClick.bind(this);
  }

  // On input change, map the input's value to it's name in state
  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSendPasswordResetClick() {
    this.props.onSendPasswordResetClick({ username: this.state.username });
  }

  checkIsSubmit(e) {
    if (e.keyCode === 13 && this.isSendPasswordResetDisabled() === false) { // keyCode 13 = enter
      this.onSendPasswordResetClick();
    }
  }

  isSendPasswordResetDisabled() {
    return this.props.loading || !this.state.username;
  }

  render() {
    return (
      <Modal
        open={this.props.show}
        title="Reset Password"
      >
        <ModalBody>
          <p className="htr-error">{this.props.errorMessage}</p>

          <Input
            name="username"
            type="text"
            placeholder="Username/Email"
            onKeyDown={this.checkIsSubmit}
            onChange={this.onInputChange}
            fullWidth
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="secondary"
            onClick={this.props.onBackToSignInClick}
          >
            Back to Sign In
          </Button>
          <Button
            type="primary"
            disabled={this.isSendPasswordResetDisabled()}
            onClick={this.onSendPasswordResetClick}
          >
            {this.props.loading ? 'Loading' : 'Send Password Reset Link'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

PasswordResetModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSendPasswordResetClick: PropTypes.func.isRequired,
  onBackToSignInClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default PasswordResetModal;
