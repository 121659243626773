const config = {
  API_BASE: '/api/v1/auth',
};

/**
 * A simple group of functions related to authentication
 */
export default {
  getUser() {
    try {
      return JSON.parse(localStorage.getItem('user_details'));
    } catch (e) {
      // Doesn't matter why, log out
      return this.logOut();
    }
  },

  saveUser(user) {
    return localStorage.setItem('user_details', JSON.stringify(user));
  },

  logIn({ origin, username, password }) {
    return new Promise((resolve, reject) => {
      fetch(`${origin}${config.API_BASE}/login`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ username, password }),
      }).then((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            this.saveUser(data);
            return resolve(data);
          });
        }

        const err = new Error(response.statusText);
        err.status = response.status;
        return reject(err);
      });
    });
  },

  logOut() {
    return localStorage.removeItem('user_details');
  },

  resetPassword({ origin, username }) {
    return new Promise((resolve, reject) => {
      fetch(`${origin}${config.API_BASE}/reset-password`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ username }),
      }).then((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            return resolve(data);
          });
        }

        // Not concerned with the status code for this call, skip assigning it to the error
        return reject(new Error(response.statusText));
      });
    });
  },

  changePassword({ origin, username, oldPassword, newPassword, confirmNewPassword }) {
    return new Promise((resolve, reject) => {
      fetch(`${origin}${config.API_BASE}/password`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          username,
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmNewPassword,
        }),
      }).then((response) => {
        response.json().then(({ data, errors }) => {
          if (response.ok) {
            return resolve(data);
          }

          const err = new Error(errors[0] || 'Server Error');
          err.status = response.status;
          return reject(err);
        }).catch(() => {
          const err = new Error('Server Error');
          err.status = response.status;
          return reject(err);
        });
      });
    });
  },
};
