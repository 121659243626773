// Vendor imports
import React from 'react';
import PropTypes from 'prop-types';

// Local imports
import { Modal, ModalBody, ModalFooter } from './Modal';
import Input from './Input';
import Button from './Button';

import '../styles.css';
/**
 * The login modal component; passes most logic up to the parent component
 */
class LoginModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.checkIsSubmit = this.checkIsSubmit.bind(this);
    this.onSignInClick = this.onSignInClick.bind(this);
  }

  // On input change, map the input's value to it's name in state
  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSignInClick() {
    this.props.onSignInClick({ username: this.state.username, password: this.state.password });
  }

  checkIsSubmit(e) {
    if (e.keyCode === 13 && this.isSignInDisabled() === false) { // keyCode 13 = enter
      this.onSignInClick();
    }
  }

  isSignInDisabled() {
    return this.props.loading || !this.state.username || !this.state.password;
  }

  render() {
    return (
      <Modal
        open={this.props.show}
        title="Hydra Login"
        onClose={this.props.onClose}
      >
        <ModalBody>
          <p className="htr-error">{this.props.errorMessage}</p>

          <Input
            name="username"
            type="text"
            placeholder="Username/Email"
            onKeyDown={this.checkIsSubmit}
            onChange={this.onInputChange}
            fullWidth
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            onKeyDown={this.checkIsSubmit}
            onChange={this.onInputChange}
            fullWidth
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="secondary"
            onClick={this.props.onResetPasswordClick}
          >
            Reset Password
          </Button>
          <Button
            type="primary"
            onClick={this.onSignInClick}
            disabled={this.isSignInDisabled()}
          >
            {this.props.loading ? 'Loading' : 'Sign In'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

LoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSignInClick: PropTypes.func.isRequired,
  onResetPasswordClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  onClose: PropTypes.func,
};

export default LoginModal;
