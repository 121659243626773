import React from 'react';

import '../styles.css';

export default (props) => {
  const { fullWidth, className, ...others } = props;
  let inputClasses = ['htr-input'];
  if (className) inputClasses = inputClasses.concat(className.split(' '));
  if (fullWidth) inputClasses.push('full-width');
  return (
    <div className="htr-input-container">
      <input className={inputClasses.join(' ')} {...others} />
    </div>
  );
};
