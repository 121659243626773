import React from 'react';

import SVGIcon from './SVGIcon';

export default () => {
  return (
    <SVGIcon>
      <g>
        <path d="M16.594 8.578l1.406 1.406-6 6-6-6 1.406-1.406 4.594 4.594z" />
      </g>
    </SVGIcon>
  );
};
